<template>
  <div
    class="min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-12 shadow-lg items-center"
  >
    <div class="bg-white w-fullrounded-lg shadow">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-5">
        <div class="flex">
          <router-link
            :class="$colors.primary"
            class="rounded-full flex justify-center items-center w-8 h-8 mt-1 mr-3"
            to="/categories"
          >
            <i class="material-icons mx-2">chevron_left</i>
          </router-link>
          <div v-if="!formData._id" class="text-2xl font-bold text-green-500 ">
            New Categorie
          </div>
          <div v-else class="text-2xl font-bold text-green-500 ">
            Edit Categorie
          </div>
        </div>
      </div>

      <div class="">
        <div class="bg-white rounded-lg p-6">
          <div class="space-y-4 px-10">
            <div
              class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="name" class="bg-white text-gray-700 px-1"
                    >Name <span :class="$colors.required">*</span></label
                  >
                </p>
              </div>
              <p>
                <input
                  id="name"
                  autocomplete="false"
                  tabindex="0"
                  type="text"
                  v-model="formData.name"
                  class="py-2 text-xs px-2 outline-none block h-full w-full"
                />
              </p>
            </div>
            <div
              class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="description" class="bg-white text-gray-700 px-1"
                    >Description <span :class="$colors.required">*</span>
                  </label>
                </p>
              </div>
              <p>
                <textarea
                  id="description"
                  autocomplete="false"
                  tabindex="0"
                  type="text"
                  v-model="formData.description"
                  class="py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full"
                ></textarea>
              </p>
            </div>
          </div>

          <div class="mt-6 pt-3 flex justify-center">
            <button
              class="rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600  hover:shadow-inner hover:bg-green-700  transition-all duration-300"
              @click="save"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- ******************* Partie Script *********************** -->
<script>
export default {
  data() {
    return {
      formData: {},
    };
  },
  mounted() {
    this.dataToEdit();
  },
  methods: {
    async save() {
      if (this.formData._id) {
        const data = await this.$server.update("categories", this.formData);
        if (data._id) {
          alert("Category updated", "success");
        }
      } else {
        if (this.checkData()) {
          const data = await this.$server.create("categories", this.formData);
          //console.log("HI souka");
          //console.log(data);
          if (data._id) {
            this.formData = {};
            alert("Category created !", "success");
          }
        } else {
          alert(`you must fill all the fields !! `, "warning");
        }
      }
    },
    checkData() {
      if (this.formData.name) return true;
      else return false;
    },

    async dataToEdit() {
      if (this.$route.params.id) {
        const editData = await this.$server.get("categories", {
          id: this.$route.params.id,
        });
        this.formData = editData.content;
        //console.log(this.formData)
      }
    },
  },
};
</script>

<!-- ******************* Partie style *********************** -->
<style >
</style>